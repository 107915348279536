.labels {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  

  &.position2 {
    top: 40%;
    left: 10px;
  }

  &.position3 {
    top: auto;
    bottom: 10px;
    left: 10px;
  }

  &.position4 {
    top: 10px;
    left: auto;
    right: 10px;
    align-items: flex-end;
  }

  &.position5 {
    top: 40%;
    left: auto;
    bottom: auto;
    right: 10px;
    align-items: flex-end;
  }

  &.position6 {
    top: auto;
    left: auto;
    bottom: 10px;
    right: 10px;
    align-items: flex-end;
  }  
  &.isBig {
    &.position6, &.position3 {
      // margin-bottom: 120px;
    }
    .label {
      padding: 8px 10px;
      font-size: 0.9em;
      line-height: 0.9em;
    }
  }
  .label {
    border-radius: 15px;
    font-weight: 600;
    // min-height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
    padding: 5px 7px;
    text-align: center;
    display: inline-block;
    font-size: 0.7em;
    line-height: 0.7em;
    color: white;
    background-color: orange;
    &.status {
      background-color: orange;
    }
    &.new {
      background-color: #64cd78;
    }
    &.favourite {
      background-color: #66cdf7;
    }
    &.discount {
      background-color: rgb(230, 59, 69);
    }
    &.delivery {
      background-color: rgb(124, 124, 124);
    }
    &.gift {
      background-color: #ff0024;
    }
    &.condition {
      background-color: #002fff;
    }
  }
}
