@import "styles/variables";

.productCardWrapper {
  width: 100%;
  padding: 20px 5px;
  .productCard {
    width: 100%;
    min-height: 370px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    border-radius: $general-radius;
    border: $general-border;
    box-shadow: $general-shadow;
    background: white;
    text-decoration: none;
    transition: $general-transition;
    padding: 10px;
    position: relative;
    &:hover {
      z-index: 2;
      box-shadow: $general-shadow-hover;
    }
    .header {
      position: relative;
      margin-bottom: $general-gutter;
      picture,
      img {
        display: block;
        margin: 0 auto;
        transition: all 0.2s;
        width: 100%;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      .name {
        text-align: left;
        color: $primary-color;
        font-weight: 600;
        line-height: 1.35em !important;
        font-size: 0.85em !important;
        height: 50px;
        font-weight: normal !important;
        overflow: hidden;
      }
      .storeStatus {
        font-size: .9em;
        font-weight: bolder;
        &.available {
          color: $available-color;
        }
        color: $unavailable-color;
      }
      .price {
        display: block;
        & span[data-property="price"] {
          font-weight: bold;
          text-align: left;
          display: block;
          color: $primary-color;
          font-size: 1.5em;
        }
        & span[data-property="priceWithoutVat"] {
          display: none;
        }
      }
    }
  }
  @media (min-width: $md) {
    padding: 20px 10px;
    .productCard {
      padding: 10px 20px;
      .content {
        .name {
          font-weight: 300;
          font-size: 1.1em;
          line-height: 1.3em;
          height: 60px;
        }
        .price {
          & span[data-property="price"] {
            font-size: 1.5em;
            color: $secondary-color;
          }
        }
      }
    }
  }
  @media (min-width: $xl) {
    .productCard {
      .content {
        .price {
          & span[data-property="price"] {
            font-size: 1.8em;
          }
        }
      }
    }
  }
}