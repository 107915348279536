@import "styles/variables";

.heurekaReviews{
    background-color: rgb(245,245,245);
    padding: $general-gutter 0;
    margin-bottom: 2*$general-gutter;
    .reviewsContainer{

        // background: url("/heureka-overene-zakaznikmi-background.png") 5% center no-repeat,
        //             url("/heureka-overene-zakaznikmi-background.png") center center no-repeat,
        //             url("/heureka-overene-zakaznikmi-background.png") 95% center no-repeat;

        background: url("/heureka-overene-zakaznikmi-background.png");
        background-repeat: space;
        background-position: center center;

        background-size: 100%;
        
        .text{
            display: block;
            
            margin-right: 50px;
            margin-bottom: $general-gutter; 
            h3{
                font-weight: bold;
                font-size: 1.8em;
                margin-bottom: 10px;
            }
        }
        .reviews{
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            width: 100%;
            .review{
                background-color: white;
                border-radius: $general-radius;
                padding: $general-gutter $general-gutter $general-gutter + 25px $general-gutter;
                box-shadow: $general-shadow;
                max-width: 250px;
                position: relative;
                margin: 0 $general-gutter;
                min-height: 200px;
                width: 50%;
                &:after{
                    content: " ";
                    display: block;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-image: url("/heureka-overene-zakaznikmi-badge.jpg");
                    background-size: cover;
                    position: absolute;
                    top: 100%;
                    margin-top: -25px;
                    left: 50%;
                    margin-left: -25px;
                }
                &:last-child{
                    display: none;
                }
                .comment{
                    max-height: 180px;
                    overflow: hidden;
                }
                .stars{
                    margin-top: 10px;
                }
            }
        }
    }

    @media (min-width: 500px) {
        .reviewsContainer{
            background-size: auto 100%;
            .reviews{
                .review{
                    &:last-child{
                        display: block;
                    }
                }
            }
        }
        
    }

    @media (min-width: 700px) {
        .reviewsContainer{
            .reviews{
                .review{
                    &:last-child{
                        display: block;
                    }
                }
            }
        }
        
    }

    @media (min-width: 900px) {
        .reviewsContainer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 50px 0;

            .text{
                width: 50%;
            }
            
            .reviews{
                .review{
                    margin: 0 0 0 $general-gutter;
                    &:last-child{
                        display: block;
                    }
                }
            }
        }
        
    }
}