@import "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600&display=swap";

$search-input-button: 60px;

$primary-color:  rgb(80, 35, 122);
$primary-color-hover:  darken($primary-color, 10%);
$secondary-color: rgb(175, 202, 5);
$text-color: rgb(17, 17, 17);
$blue-color: rgb(0, 89, 255);
$grey-color:  rgb(183, 183, 183);
$grey-light-color:  rgb(238, 238, 238);
$grey-dark-color:  rgb(172, 172, 172);
$discount-color:  rgb(230, 59, 69);
$available-color: rgb(40, 167, 69);
$unavailable-color: rgb(220, 53, 69);
$general-gutter: 15px;
$xs: 480px;
$sm: 766px;
$md: 991px;
$lg: 1200px;
$xl: 1400px;

#finder{
	max-width: 100%;
    // padding: 15px 20px 25px;
    border-radius: 12px;
	*{
		box-sizing: border-box;
		font-family: 'Quicksand', sans-serif;
	}
	h2{
		color: $primary-color;
		font-weight: bold;
		font-size: 1em;
		padding: 0;
		margin: 0;
		margin-bottom: 20px;
	}
	.custom-select{
		width: 100%;
		height: 32px;
		margin-bottom: 15px;
		display: block;
		background-clip: padding-box;
		border: none;
	}
	.footer{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.link{
			font-size: 0.8em;
			text-decoration: underline;
			color: $primary-color;
		}
		.submit{
			display: inline-block;
			color: white;
			background-color: $primary-color;
			padding: 5px 15px;
			font-size: 0.8em;
			border-radius: 12px;
            font-weight: bold;
            &:hover {
                background-color: white;
                color: $primary-color;
                text-decoration: underline;
            }
		}
	}
}
