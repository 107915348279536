@use "sass:math";
@import "styles/variables";

$sidebarWidth: calc(25% - #{math.div($general-gutter, 2)});
$mainWidth: calc(75% - #{math.div($general-gutter, 2)});
$homepage-grey-color: $secondary-background;

$desktop-accessoryFinder-breakpoint: 1300px;

.wrapper {
  box-sizing: border-box;
  .arrow {
    background-color: white;
    box-sizing: border-box;
    color: $primary-color;
    fill: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 40px;
    height: 40px;
    border-radius: math.div($general-gutter, 2);
    box-shadow: $general-shadow;
    font-size: 0.8em;
    &:hover {
      background-color: $secondary-color;
      color: $primary-color;
    }
    &::before {
      content: "";
    }
    &.left {
      left: 15px;
      top: calc(50% - 20px);
    }
    &.right {
      right: 15px;
      top: calc(50% - 20px);
    }
    @media (max-width: $md) {
      display: none;
    }
  }
}

.bannersRow {
  background-color: white;
  position: relative;
  box-sizing: border-box;
  @media (min-width: $sm) {
    background-color: $homepage-grey-color;
    padding: calc(2 * #{$general-gutter}) $general-gutter;
  }
  &:before {
    display: block;
    content: " ";
    width: 100%;
    margin-top: calc(-2 * #{$general-radius});
    height: calc(75px + 2 *#{$general-radius});
    background-color: $primary-color;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    position: absolute;
    top: 0;
    left: 0;
    @media (min-width: $sm) {
      display: none;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .sidebar {
      display: none;
      width: $sidebarWidth;
      @media (min-width: $sm) {
        display: block;
      }
      h2 {
        text-align: center;
        color: $primary-color;
        text-transform: uppercase;
        font-size: 1em;
        font-weight: bold;
        margin-bottom: calc(2 *#{$general-gutter});
      }
      .customBanners {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .customBanner {
          width: 73px;
          a {
            display: block;
          }
          picture,
          img,
          .imageWrapper {
            background-color: white;
            border-radius: $general-radius;
            font-size: 0;
            transition: $general-transition;
          }
          .title {
            text-align: center;
            font-size: 0.9em;
            line-height: 1.2em;
            margin: 5px 0;
          }
          &:hover {
            .imageWrapper {
              box-shadow: $general-shadow-hover;
            }
          }
        }
      }
      .divider {
        display: none;
        width: 100%;
        height: 1px;
        background-color: #dfdfe0;
        margin: calc(2 *#{$general-gutter}) 0;
      }
      .accessoryFinder {
        display: none;
      }
      @media (min-width: $desktop-accessoryFinder-breakpoint) {
        .accessoryFinder,
        .divider {
          display: block;
        }
      }
    }
    .main {
      display: block;
      width: 100%;
      @media (min-width: $sm) {
        width: $mainWidth;
      }
      .homepageBanner {
        display: block;
        padding: $general-gutter calc(2 * #{$general-gutter}) $general-gutter;

        @media (min-width: $sm) {
          padding: 0;
        }
        .imageWrapper {
          box-shadow: $general-shadow-hover;
          border-radius: $general-radius;
          font-size: 0;
          @media (min-width: $sm) {
            box-shadow: none;
          }
        }

        picture,
        img {
          display: block;
          width: 100%;
          max-width: 100%;
          height: auto;
          border-radius: $general-radius;
        }
      }
    }
  }
}

.searchRow {
  .content {
    padding: $general-gutter;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    input {
      width: 100%;
      height: 40px;
      border-radius: $general-radius;
      border: none;
      padding: 5px $general-gutter;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    }
    button {
      border: none;
      background-color: transparent;
      color: $primary-color;
      cursor: pointer;
      margin-left: 10px;
      margin-bottom: -5px;
      svg {
        fill: $primary-color;
        height: 45px;
        width: 45px;
        transition: $general-transition;
      }
      &:hover {
        svg {
          transform: scale(1.1);
        }
      }
    }
  }
  @media (min-width: $xs) {
    display: none;
  }
}

.marketingRow {
  margin-top: $general-gutter;
  .content {
    display: flex;
    flex-direction: column;
    .accessoryFinder {
      order: 2;
      width: 100%;
    }

    .categories {
      order: 1;
      margin-bottom: $general-gutter;
      display: flex;
      flex-direction: row;
      padding: 0 $general-gutter;
      flex-wrap: wrap;
      .category {
        display: block;
        text-align: center;
        padding: 3px;
        width: 33%;
        border-radius: $general-radius;
        .content {
          max-width: 75px;
          margin: 0 auto;
          .imageWrapper {
            background-color: $homepage-grey-color;
            font-size: 0;
            border-radius: $general-radius;
            transition: $general-transition;
            picture,
            img {
              border-radius: $general-radius;
            }
            &:hover {
              box-shadow: $general-shadow-hover;
              background-color: white;
            }
          }
          .title {
            margin-top: 10px;
            text-align: center;
            width: 100%;
          }
        }
      }
    }

    .marketingInfo {
      order: 3;
      width: 100%;
    }

    @media (min-width: 350px) {
      .categories {
        .category {
          width: 25%;
        }
      }
    }

    @media (min-width: $xs) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .accessoryFinder {
        order: 1;
        flex: 2;
      }
      .categories {
        order: 2;
        flex: 3;
        margin-left: $general-gutter;
        .category {
          .content {
            max-width: 50px;
            .title {
              font-size: 0.8em;
            }
          }
        }
      }
      .marketingInfo {
        width: 100%;
      }
    }

    @media (min-width: $sm) {
      .accessoryFinder {
        flex: auto;
        width: $sidebarWidth;
      }
      .categories {
        flex: auto;
        width: $mainWidth;
        .category {
          .content {
            max-width: 75px;
            .title {
              font-size: 1em;
            }
          }
          &.banner {
            display: none;
          }
        }
      }
      .marketingInfo {
      }
    }

    @media (min-width: $desktop-accessoryFinder-breakpoint) {
      .accessoryFinder {
        display: none;
      }
      .categories {
        display: none;
      }
      .marketingInfo {
      }
    }
  }
}

.carouselsRow {
  background-color: $homepage-grey-color;
  border-radius: $general-radius;
  padding: $general-gutter 0;
  .productsWrapper {
  }
  @media (min-width: $sm) {
    background-color: white;
    border-radius: 0;
    .productsWrapper {
      background-color: $homepage-grey-color;
      border-radius: $general-radius;
      border: $general-border;
      :global(.slick-slider) {
        padding: calc(2 *#{$general-gutter} - 10px) calc(2 *#{$general-gutter});
      }
    }
  }
}

.banners-location2-section {
  padding: 20px 0;
  picture,
  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

h2.title {
  margin: $general-gutter 0;
  padding-left: $general-gutter;
  color: $primary-color;
  font-size: 1.8em;
  font-weight: 600;
  position: relative;
  display: block;
  width: 100%;
  &::after {
    content: " ";
    height: 1px;
    background-color: #737373;
    top: 50%;
    left: $general-gutter;
    right: $general-gutter;
    position: absolute;
    z-index: 1;
  }
  &.simple {
    &:after {
      display: none;
    }
  }
  .textWrapper {
    display: inline-block;
    background-color: $homepage-grey-color;
    padding-right: $general-gutter;
    z-index: 2;
    position: relative;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  @media (min-width: $sm) {
    &::after {
      display: none;
    }
    .textWrapper {
      background-color: transparent;
      padding: 0;
    }
  }
  @media (min-width: $xl) {
    padding-left: 0;
  }
}

.blogsRow {
  margin-bottom: $general-gutter;
  :global(.slick-track) {
    display: flex;

    :global(.slick-slide) {
      height: auto;

      & > div {
        display: flex;
        height: 100%;
      }
    }
  }

  .arrow {
    @media (max-width: $md) {
      display: block;
      &.left {
        right: 60px;
        left: auto;
        top: -30px;
      }
      &.right {
        right: 10px;
        top: -30px;
      }
    }
  }
}

.brandsRow {
  margin-top: $general-gutter;
  .brands {
    background: $homepage-grey-color;
    border: $general-border;
    padding: calc(2 *#{$general-gutter}) $general-gutter;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: $general-radius;
    margin-bottom: calc(2 *#{$general-gutter});
    &::after {
      content: "";
      flex: auto;
    }
    .brand {
      padding: 10px;
      max-width: 33%;
      picture,
      img {
        max-width: 100%;
        display: block;
        filter: grayscale(1);
        opacity: 0.6;
        transition: $general-transition;
      }
      &:hover {
        picture,
        img {
          filter: none;
          opacity: 1;
        }
      }
    }
  }
  @media (min-width: $xs) {
    .brands {
      margin-bottom: 0;
      border-radius: 0;
      -webkit-border-top-left-radius: $general-radius;
      -webkit-border-top-right-radius: $general-radius;
      -moz-border-radius-topleft: $general-radius;
      -moz-border-radius-topright: $general-radius;
      border-top-left-radius: $general-radius;
      border-top-right-radius: $general-radius;
      .brand {
        max-width: 25%;
      }
    }
  }
  @media (min-width: $sm) {
    .brands {
      .brand {
        max-width: 20%;
      }
    }
  }
  @media (min-width: $md) {
    .brands {
      .brand {
        width: 16.6%;
      }
    }
  }
}
