@import "styles/variables";

.blogCardWrapper {
  padding: 10px;
  .blogCard {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    background-color: white;
    border: 1px solid white;
    transition: all 0.1s linear 0s;
    border-radius: 5px;

    &:hover {
      z-index: 2;
      border-color: rgba(0, 0, 0, 0.05);
      box-shadow: 0px 25px 60px -12px rgba(0, 0, 0, 0.05);
    }

    .content {
      picture,
      img {
        max-width: 100%;
        width: 100%;
        margin-bottom: 10px;
      }

      .name {
        font-weight: bold;
        font-size: 1.2em;
        color: rgb(80, 35, 122);
        min-height: 55px;
        display: block;
        margin: 20px 0px 15px;
        text-decoration: none;
      }
      .perex {
        font-size: 0.95em;
        // color: rgb(80, 35, 122);
        color: $text-color;
        margin-bottom: 20px;
      }
    }

    .footer {
      .button {
        display: inline-block;
        width: auto;
        border: 1px solid rgb(175, 202, 5);
        color: rgb(175, 202, 5);
        padding: 10px 40px;
        border-radius: 25px;
        margin: 0 auto 0 0;
        &:hover {
          border-color: rgb(123, 153, 0);
          color: rgb(123, 153, 0);
        }
      }
    }
  }
}
